<template>

    <section class="loading__screen">
        <svg  class="loading--logo" width="483" height="500" viewBox="0 0 483 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path   id="logo__circle" class="round animation"
                    d="M242.363 54.0513C208.568 54.7381 175.295 64.7241 146 81.3208C167.438 97.1048 188.881 112.882 210.318 128.667C230.768 122.716 252.439 121.75 273.425 126.159C302.667 132.223 329.402 148.847 347.795 172.503C350.229 175.615 352.52 178.833 354.837 182.032C369.492 169.949 387.134 160.91 406.205 158.643C411.089 157.932 416.006 157.47 420.908 156.892C403.628 126.302 378.702 99.8139 348.297 81.8573C316.551 62.9207 279.364 53.202 242.363 54.0513ZM353.579 320.456C334.745 348.525 304.493 368.925 271.046 375.05C250.879 378.986 230.159 377.76 210.44 372.13C189.001 387.925 167.56 403.718 146.122 419.515C175.92 436.363 209.835 446.322 244.195 446.721C298.694 447.745 353.073 424.837 390.378 385.343C402.319 372.869 412.204 358.628 420.992 343.83C406.268 342.538 391.191 340.355 378.01 333.758C369.642 329.812 362.133 324.345 354.814 318.759C354.402 319.324 353.991 319.89 353.579 320.456Z" 
                    fill="white"
                />
            <path   id="logo__moon" class="round animation"
                    d="M417.572 182.016C391.978 182.358 367.233 198.06 356.509 221.238C345.933 243.113 348.352 270.613 362.981 290.11C376.914 309.516 401.528 320.704 425.407 318.214C444.005 316.424 462.472 307.311 473.256 291.83C476.802 286.37 479.66 280.507 482.859 274.845C473.696 280.336 463.688 285.653 452.67 285.371C441.126 285.46 429.136 278.542 423.052 268.918C420.073 264.354 418.261 258.703 417.732 253.32C416.495 239.904 423.986 225.556 436.416 219.355C446.962 213.651 460.071 214.124 470.734 219.28C474.885 221.067 478.763 223.409 482.782 225.46C476.093 213.476 469.027 200.831 457.088 193.249C445.403 185.741 431.495 181.711 417.572 182.016Z" 
                    fill="white"
                />
            <path   id="logo__arrow--big" class="pointy animation"
                    d="M0.000197112 4.07752C0.0163446 30.8343 0.0388848 57.591 0.0534175 84.3477C75.0997 139.563 150.14 194.786 225.184 250.004C150.22 305.228 75.2508 360.446 0.289755 415.675C0.305903 443.783 0.331673 471.892 0.342976 500C113.464 416.667 226.588 333.338 339.707 250.004C226.475 166.664 113.233 83.3386 0 0C0 1.3592 0.000197112 2.71832 0.000197112 4.07752Z" 
                    fill="white"
                />
            <path   id="logo__arrow--smol" class="pointy animation"
                    d="M24.9998 164.085C25.0006 175.214 25.0157 186.342 25.0157 197.471C36.9292 206.273 48.8432 215.075 60.7567 223.877C60.7567 240.293 60.7567 256.709 60.7567 273.125C48.8685 281.915 36.9804 290.704 25.092 299.493C25.092 311.981 25.1082 324.469 25.1082 336.957C31.0199 332.584 36.9354 328.215 42.8491 323.845C42.8491 328.215 42.8652 332.586 42.8652 336.957C48.8311 332.544 54.799 328.135 60.766 323.723C60.766 329.206 60.766 334.688 60.766 340.17C100.655 309.553 140.612 279.023 180.533 248.448C140.614 218.957 100.686 189.479 60.7585 160C60.7585 164.431 60.7585 168.862 60.7585 173.294C54.756 168.862 48.7508 164.435 42.7509 160C42.7509 164.373 42.7509 168.746 42.7671 173.119C36.851 168.746 30.9333 164.375 25.0185 160C25.0185 161.362 25.0185 162.723 25.0185 164.085H24.9998Z" 
                    fill="white"
                />

        </svg>
        <div class="loading--backdrop">
            <div class="stripe__container">
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
            </div>
            <div class="stripe__container">
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
                <div class="stripe"></div>
            </div>
        </div>
    </section>



</template>


<style scoped>
  @import '@/assets/drip.css';


/*                         */
/* SPLASH SCREEN / LOADING */
/*                         */


.loading__screen {
    position: fixed;
    inset: 0;
    background: var(--bg_main, #555);

    pointer-events: none;
    overflow: hidden;
    z-index: 9990;
}
.loading--logo {
    --_logo-loadsize:30vh;

    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 9995;
    overflow: visible;
    transform-style: preserve-3d;
    perspective: 100px;
    
    width: var(--_logo-loadsize);
    aspect-ratio: 1;
}
.loading--logo {
    --_load--scale:1;
    --_load--offsetX:0;
    --_load--offsetY:0;
    --_spin--X:0;
    --_spin--Y:0;
    --_spin--Z:0;
    --_spin--deg:0;
}
.animation {
    transform-origin: center;
    animation:  load 600ms ease forwards,
                pulse 1200ms infinite alternate ease 1200ms;
}
.loading--logo #logo__moon.round {
    --_load--offsetX: 150vw;
}
.loading--logo #logo__arrow--smol.pointy {
    --_load--scale: 2;
    --_load--offsetX: -150vw;
}
.loading--logo #logo__circle.round {
    --_load--scale: 10;
    --_load--offsetX: 20vw;
}
.loading--logo #logo__arrow--big.pointy {
    --_load--scale: 4;
    --_load--offsetX: -50vw;
}
/* spinner variation */
.--spin .animation {
    transform-origin: center;
    animation: spin 5s cubic-bezier(0.42, 0.16, 0.21, 1.02) infinite;
}
.loading--logo.--spin #logo__moon.round {
    --_spin--X:1;
    --_spin--deg:180deg;
}
.loading--logo.--spin #logo__arrow--smol.pointy {
    --_spin--X: 1;
    --_spin--deg:180deg;
}
.loading--logo.--spin #logo__circle.round {
    --_spin--Z:1;
    --_spin--deg:360deg;
}
.loading--logo.--spin #logo__arrow--big.pointy {
    --_spin--X:1;
    --_spin--deg:-360deg;
}



/* splashscreen stripes */
.loading--backdrop {

    --_stripe-offset: 30vw;
    --_stripe-lenght-start: 100vw;
    --_stripe-lenght-end: 30vw;
    /* --_stripe-speed: 600ms; */
    --_stripe-speed: 1800ms;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.stripe__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block: 1rem;
}
.stripe {
    height: 1rem;
    border-radius: 1rem;
    background: #fff1;
    background-color: var(--info_main, whitesmoke);
    opacity: .1;
}
.stripe:nth-child(even) {
    align-self: flex-end;

    transform: translateX(calc(var(--_stripe-offset-end) * -1 ));
    animation: idleStripe var(--_stripe-speed) infinite alternate ease-in-out;
}
.stripe:nth-child(odd) {
    --_stripe-lenght-start: 30vw;
    --_stripe-lenght-end: 100vw;

    transform: translateX(var(--_stripe-offset-start));
    animation: idleStripe var(--_stripe-speed) infinite alternate-reverse ease-in-out;
}

</style>