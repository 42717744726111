<template>
    <nav 
        :data-appearance="navType"
        :class="navMenuClasses"
        @mouseleave="navState('closeAll')"
        >

        <div class="menu">

            <div id="burger"
                class="burger"
                @click.prevent="navState('auto')"
                >
                <div class="burger--line higher"></div>
                <div class="burger--line middle"></div>
                <div class="burger--line lower"></div>
            </div>
    
            <div class="logo">
                <p>
                    The<br>Anidaza<br>Cartel
                </p>
                <icon 
                    :name="'logo'" 
                    :class="'logo--img'"
                    />
            </div>
    
        </div>
        
        <ul class="list">

            <li v-for="(page, index) in pages" :key="index">
                
                <router-link :to="{ name: page.slug }"  
                    aria-current="page"
                    :title="`this link goes to the ${page.slug} page`"
                    >
                    <icon 
                        :name="page.slug" 
                        :class="'icon'"
                        />

                    {{ page.name }}

                </router-link>

            </li>

        </ul>
    
        <div    class="settings"
                :class="{'': !openSettings || !openNav, '--open': openSettings}"
                >

            <icon 
                :name="'cog'"
                :class="'icon'"
                alt="access quick settings"
                @click.prevent="openSettings = !openSettings"
                ></icon>

            <div class="settings--quick">
    
                <form   v-for="form in navSettingsMenu"
                        :name="form.name"
                        >
                    <label  v-for="setting in form.setting"
                            :for="setting.for + '-' + setting.value">
                        <input  type="radio" 
                                :name="setting.for + '-setting'" 
                                :id="setting.for + '-' + setting.value" 
                                :value="setting.value"
                                @click="quickSetting(setting.value, setting.for)"
                                :checked="checkValues(setting.value)"
                                >
                        <span class="checkmark"></span>
                        <span class="label">{{setting.for}}: {{setting.value}}</span>
                    </label>
                </form>
    
            </div>


        </div>
    </nav>


</template>



<script>
import Icon from './svg/IconElements.vue'

export default {
            components: {
                Icon
            },
            created() {
                this.getSettings();
            },
            props: ['pages', 'current', 'navLinkClick', 'themeClick'],
            computed: {
                navMenuClasses() {
                    return {
                        '--open': this.openNav || this.openSettings,
                        '--close': !this.openNav
                    }
                }
            },
            data(){
                return {
                    activePage: 0,
                    currentPage: false,
                    theme: 'default',
                    navType: 'fixed',
                    setValues: ['default', 'default'],
                    openNav: false, 
                    openSettings: false,
                    navSettingsMenu: [
                    {
                        name: 'nav-settings',
                        setting: [
                        {
                            for: 'nav',
                            value:'fixed',
                            checked: false
                        },
                        {
                            for: 'nav',
                            value:'hidden',
                            checked: true
                        }
                        ]
                    },
                    {
                        name: 'theme-settings',
                        setting: [
                            {
                                for: 'theme',
                                value:'polarized',
                                checked: false
                            },
                            {
                                for: 'theme', 
                                value:'glass',
                                checked: false
                            }, 
                            {
                                for: 'theme',
                                value:'sunflare',
                                checked: false,
                            }, 
                            {
                                for: 'theme',
                                value:'default',
                                checked: true,
                            }
                        ]
                    }
                    ],
                }
            },
            methods: {
                navState(state) {
                    switch(state){
                        case 'closeAll':
                            this.openNav = false
                            this.openSettings = false
                            break
                        case 'openAll':
                            this.openNav = true
                            this.openSettings = true
                            break

                        case 'auto':
                            this.openNav || this.openSettings ? this.openNav = false : this.openNav = true
                            this.openSettings = false
                        default:
                            break
                    }
                },
                quickSetting(value, desto) {
                    if (desto == 'nav') {
                        this.navType = value;
                        
                    }
                    if (desto == 'theme') {

                        this.themeClick(value);
                        this.theme = value;
                    }
                    this.storeSetting(desto, value);
                },
                storeSetting(key, value) {
                    localStorage.setItem(key, value)
                },
                getSettings() {
                    let themeSetting = localStorage.getItem('theme')
                    let navTypeSetting = localStorage.getItem('nav')

                    if (themeSetting) {
                        this.theme = themeSetting
                        this.themeClick(themeSetting)
                    }
                    if (navTypeSetting) {
                        this.navType = navTypeSetting
                    } 
                },
                checkValues(value) {
                    let setValues = [this.theme, this.navType]
                    // return boolean:
                    return setValues.includes(value)
                },
                checkDevice() {
                    return window.matchMedia("only screen and (max-width: 600px)").matches;
                    // isMobile ? this.navType = 'hidden' : this.navType = 'fixed'
                }
            },
            mounted () {
                // on load, check for user nav setting and set depending on that and device width
                let navTypeSetting = localStorage.getItem('nav');
                if (navTypeSetting) {
                    this.navType = navTypeSetting;
                } else {
                    if (this.checkDevice()) {
                    this.navType = 'hidden';
                    } else {
                    this.navType = 'fixed';
                    }
                    this.quickSetting(this.navType, 'nav');
                }
            }
}
</script>

<style>
  @import '@/assets/drip.css';

nav {
    --_nav-logosize: 4rem;
    --_nav-text-color: var(--inter_hover);
    --_nav-current-color: var(--accent_base);
    --_nav-current-size: 3rem;
    --_nav-current-weight: 15rem;
    --_nav-bg: linear-gradient(to bottom, var(--bg_cont) 60%, var(--bg_main), var(--bg_main-half));
    --_nav-iconwidth: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    justify-content: flex-start;
    
    font-family: 'Inter', 'Arial', sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    letter-spacing: 3px;

    height: 100vh;
    height: 100dvh;
    width: var(--nav-width);
    overflow: hidden;
    padding: .5rem;

    z-index: 110;
    
    position: fixed;
    top: 0;
    left: 0;

    background: #007d7e;
    background: transparent;

    transition: width 300ms ease-out 200ms,
                background 250ms ease-in,
                color 250ms ease-in-out;
}


nav .logo,
nav .menu {
    display: flex;
    flex-direction: row;
}
nav .menu {
    height: var(--_navlogosize);
    gap: 2.3rem;
}
nav .logo {
    pointer-events: none;
    user-select: none;
}


nav .list {
    display: flex;
    flex-direction: column;
    margin-block-start: 3rem;
    gap: 1rem;

    opacity: 0;
    transition: opacity 350ms ease-in;
}
nav .list a {
    display: flex;
    align-items: center;
    gap: .9rem;
    padding-inline-end: 1.5rem;
    background: linear-gradient(to right, transparent, var(--_nav-current-color, azure));
    background-size: var(--_nav-current-weight) var(--_nav-current-size);
    background-repeat: no-repeat;
    background-position: 20rem center;
    transition: background-position 250ms ease-in-out,
                background-size 300ms ease-in-out;
}

/* Navigation Icons */
nav .icon {
    width: var(--_nav-iconwidth);
    aspect-ratio: 1;
}
nav .logo--img {
    height: var(--_nav-logosize);
    aspect-ratio: 1;
}
svg {
    width: inherit;
    height: inherit;
}

/* Nav Text */
nav .logo {
    color: var(--info_main, whitesmoke);
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: end;
    font-weight: 800;
    opacity: 0.1;
}
nav .logo p {
    font-size: inherit;
    font-weight: 800;
    line-height: 1.45rem;
    margin-block-start: -0.2rem;
}

nav a {
    color: var(--info_main, whitesmoke);
    text-decoration: none;
    
    font-size: 1rem;
    line-height: 1rem;
}

/* Navigation Transitions */
nav:hover {
    /* width: 17.5rem; */
    background: var(--bg_main-half, #2225);
    background: var(--_nav-bg);
    backdrop-filter: blur(30px);
}

nav:hover .list {
    opacity: 1;
}
nav .list a:hover {
    --_nav-current-color: var(--accent_hover);
}
nav .list a.--currentpage {
    --_nav-current-color: var(--accent_base);
}
nav .list a:hover,
nav .list a.--currentpage {
    --_nav-current-size: .5rem;
    --_nav-current-weight: 0.3rem;

    background-position: calc(var(--_nav-iconwidth) + 0.2rem) center;
}
nav .list a.--currentpage {
    --_nav-current-size: .75rem;
}
nav:hover .burger--line {
    width: var(--_burger-size);
}



/* Open navigation menu */

nav.--open {
    width: 17.5rem;
    background: var(--_nav-bg);
    backdrop-filter: blur(30px);
}
nav.--open .list {
    opacity: 1;
}


.burger {
    --_burger-size: var(--_nav-iconwidth);
    --_burger-color: var(--info_main, azure);
    --_burger-weight: .2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(var(--_burger-weight) * 1.5 );
    height: var(--_burger-size);

    transform-origin: center;
    /* transform: rotate(360deg); */

    transition: width 150ms ease-in-out,
                background 200ms ease-in-out,
                transform 250ms ease-in,
                opacity 200ms ease;
}
.burger--line {
    position: relative;
    
    height: calc(var(--_burger-weight) / 2 );
    width: var(--_burger-size);

    background: var(--_burger-color);
    border-radius: var(--_burger-weight);

    transition: width 250ms ease-in-out,
                background 550ms ease-in-out,
                transform 250ms ease-in;
}
.burger--line.middle {
    width: calc(var(--_burger-size) * 0.75 );
}
.burger--line.lower {
    width: calc(var(--_burger-size) * 0.5 );
}

/* burger to X */
nav.--open .burger {
    transform-origin: center;
    transform: rotate(-90deg);
    transition: transform 250ms ease-in;
}
nav.--open .burger .burger--line {
    --_burger-color: var(--accent_base, #a20);
    
    height: calc(var(--_burger-weight) * 2 );
    width: var(--_burger-size);
    transition: height 350ms ease-in-out,
                width 150ms ease-in-out,
                background 250ms ease-in-out,
                transform 250ms ease-in;
}
nav.--open .burger .burger--line.higher {
    transform:  translateY(calc(var(--_burger-weight) * 3.4 ))
                rotate(-45deg);
                /* rotate(-225deg); 
                */
}
nav.--open .burger .burger--line.lower {
    transform:  translateY(calc(var(--_burger-weight) * -3.4 ))
                rotate(-135deg);
                /* rotate(-315deg); 
                */
}
nav.--open .burger .burger--line.middle {
    opacity: 0;
    height: var(--_burger-size);
}





/* quick settings in nav */
nav .settings form {
    margin-block-end: 1rem;
}
nav .settings {
    display: flex;
    flex-direction: column;
    margin-block-start: auto;
}
nav .settings .icon  {
    order: 3;
}



nav .settings--quick {
    transform-origin: bottom;
    transform: scaleY(0);
    opacity: 0;
    transition: transform 200ms ease-out 250ms;
}
nav .settings.--open .icon {
    animation: roll 10000ms infinite linear;
}

nav .settings.--open .settings--quick {
    transform: scaleY(1);
    opacity: 1;
    padding-block: 1rem;
    transition: transform 300ms ease-out,
                opacity 200ms ease-in 200ms;
}

.settings--quick label {
    display: grid;
    grid-template-columns: .5fr 4fr .01fr;
    align-items: center;
    margin-inline-start: 1.5rem;
    gap: .5rem;
    padding-block: .25rem;
}
.settings--quick input {
    visibility: hidden;
}
.settings--quick .checkmark {
    grid-column: 1;
    grid-row: 1;
    height: .6rem;
    aspect-ratio: 1;
    border-radius: 1rem;
    /* border: 1px solid #fff; */
    outline: 1px solid var(--info_main, whitesmoke);
    outline-offset: .2rem;
}
.settings--quick input:checked + .checkmark{
    background: var(--info_main, whitesmoke);
}
.settings--quick .label {
    grid-column: 2;
    grid-row: 1;
    font-size: .8rem;
}



/* Nav Appearance: "Fixed" (always visible) */
nav[data-appearance="fixed"] {
    backdrop-filter: blur(30px);
    background: var(--_nav-bg);
}
nav[data-appearance="fixed"] .list {
    opacity: 1;
}
#entry ~ nav[data-appearance="fixed"] {
    backdrop-filter: initial;
    background: initial;
}
#entry ~ nav[data-appearance="fixed"] .list {
    opacity: 0;
}
#entry ~ nav[data-appearance="fixed"]:hover {
    backdrop-filter: blur(30px);
    background: var(--_nav-bg);
}
#entry ~ nav[data-appearance="fixed"]:hover .list {
    opacity: 1;
}

</style>