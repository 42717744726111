import { createRouter, createWebHistory } from "vue-router";
import Entry from "../views/EntryView.vue";

const routes = [
  {
    path: "/", name: "entry", component: Entry,
    meta: {
      title: '',
      transition: 'default'
    }
  },
  {
    path: "/home", name: "home", component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue'),
    meta: {
      title: ' | Home, News & About Us: The Anidaza Cartel | Eve Online Community',
      transition: 'default'
    }
  },
  {
    path: "/tools", name: "tools", component: () => import(/* webpackChunkName: "tools" */ '@/views/ToolsView.vue'),
    meta: {
        title: ' | Tools & Services for Eve Online',
        transition: 'default'
      },
    children: [
      { path: '', name: 'tools_overview',
        component: () => import('@/views/ToolOverviewShow.vue'),
        meta: {
          title: ' | Tools & Services for Eve Online', 
          transition: 'default'
        }
      }
      ,
      { path: 'guides', name: 'tools_guides',
        component: () => import('@/views/ToolGuidesShow.vue'),
        meta: {
          title: ' | Tools & Services for Eve Online | Knowledge Repository aka Space Guides', 
          transition: 'fade'
        }
      }
      ,
      { path: 'hauling', name: 'tools_hauling',
        component: () => import('@/views/ToolHaulingShow.vue'),
        meta: {
          title: ' | Tools & Services for Eve Online | Hauling Calculator', 
          transition: 'fade'
        }
      }
    ]
  },
  {
    path: "/legal", name: "legal", component: () => import(/* webpackChunkName: "legal" */ '@/views/LegalView.vue'),
    meta: {
      title: ' | Legal Disclaimer',
      transition: 'default'
    }
  },
  {
    path: "/articles/:slug", name: "articles", component: () => import(/* webpackChunkName: "article" */ '@/views/ArticleView.vue'),
    meta: {
      title: ' | Articles',
      transition: 'default'
    },
    children: [
      { path: '', name: 'article-view',
        component: () => import('@/components/ArticleShow.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: ' | This page is not available',
      transition: 'default'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: `--currentpage`,
})

router.beforeEach((to, from) => {
  document.title = '· Anidaza · ' + to.meta.title || '· Anidaza · ';
})

router.afterEach((to, from) => {
  // transition fade only if navigation stays within tools route
  if (to.path === '/tools' && from.path.startsWith('/tools/')) {
    to.meta.transition = 'fade'
  }
})


export default router;
