<template>
<main>

<section id="landing" style="position: fixed; inset: 0;">
    <div class="hero">

        <div class="circles noselect">
            <img class="circle--01 noselect" src='@/assets/bg/circle--01--dotted.svg'>
            <img class="circle--02 noselect" src='@/assets/bg/circle--02.svg'>
            <img class="circle--03 noselect" src='@/assets/bg/circle--03.svg'>
            <img class="circle--04 noselect" src='@/assets/bg/circle--04.svg'>
            <img class="circle--05 noselect" src='@/assets/bg/circle--05.svg'>
        </div>

        <div class="txt noselect">
            <h1>Anidaza Expat Community</h1>
        </div>

        <div v-if="maintenance" class="txt-sub noselect">
            <h3>Website is currently under maintenance</h3>
        </div>

        
        <div v-else class="buttons">
            <router-link :to="{ name: pages[0].slug }" class="btn">Enter</router-link>
            <router-link :to="{ name: pages[1].slug }" class="btn --accent">Tools</router-link>
        </div>
        
    </div>

    <div class="hero--backdrop noselect" style="background: var(--wolf, #045); background-position: center center; background-size: auto 120%;">
        <div class="logo-fragment" style="background: var(--logo_plainwhite); background-position: right -10vh; background-size: 165vh;">
            <div class="adjustment-filter" style="height:100vh; width: 100vw; background: #E9C69C; mix-blend-mode: exclusion;">
            </div>
        </div>
    </div>


</section>
    


</main>
</template>

<script>
export default {
    props: ['pages', 'articles', 'setLocations', 'locs', 'maintenance']
}
</script>

<style lang="scss" scoped>
@use "sass:list";

.transitionslide-enter-active,
    .transitionslide-leave-active {
      opacity: 1;
      transition: opacity 500ms, transform 450ms;
    }
    .transitionslide-enter-from {
      opacity: 0;
      transform: translateY(0);
    }
    .transitionslide-leave-to {
      opacity: 0;
      transform: translateY(-100%);
    }

    
@keyframes enterHero {
    from {
        opacity: 1;
        z-index: 1100;
    }
    to {
        opacity: 0;
        z-index: 100;
    }
}

@mixin tablet {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 600px) {
    @content;
  }
}



.hero {
    position: absolute;
    top: 0; left: 0;
    z-index: 105;
    overflow: hidden;
    height:100vh; width: 100vw;

    .txt, .txt-sub {
        position: relative;
        top: calc(50% - 7.5rem);
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 1rem;
        padding-block: var(--_headroom);
        background: var(--_bg-hero-txt);
        backdrop-filter: blur(var(--_blur));
    }
    .txt {
        --_blur: 60px;
        --_bg-hero-txt: transparent;
        --_headroom: 2rem;

        @include tablet {
            padding-inline: 0;
            margin-inline: 0;

            @include mobile {
                top: calc(25% - 3rem);
            }
        }

        h1 {
            font-family: 'Inter', 'Arial', sans-serif;
            text-transform: uppercase;
            text-align: center;
            font-size: 3.5rem;
            font-weight: 200;
            letter-spacing: 1rem;
            color: var(--info_main, whitesmoke);

            margin-block: 1rem;

            @include tablet {
                font-size: 3.3rem;
                padding-inline: 0;
                margin-inline: 0;

                @include mobile {
                    font-size: 3rem;
                    font-weight: 100;
                    letter-spacing: .3rem;
                    padding-inline: 0;
                    margin-inline: 0rem;
                }
            }
        }

 
    }
    
    .txt-sub {
        --_blur:5px;
        --_bg-hero-txt:#fff7 ;
        --_headroom:1rem ;

        gap:1rem ;
        margin-inline:auto ;
        margin-block-start: 1rem;

        @include tablet {
            position :fixed ;
            top :1rem ;
            width :100% ;
            margin-inline :0 ;
        }

        h3 {
            font-weight: 600;
            letter-spacing: .2rem;
            color: #a02;
            text-align: center;
            padding-inline-start: 0;
            margin-block: 0;
        }
        p {
            color:#a02 ;
            text-align:center ;
            padding-inline-start :0 ;
            margin-block-start :0 ; 
        }
    }

    .circles {
        position: absolute;
        inset: 0;
        top: -14.5rem;

        display: grid;
        justify-content: center;
        place-items: center;

        img {
            --_circle-duration: 15s;
            --_circle-size: 28rem;
            --_circle-animation: roll;

            grid-column: 1;
            grid-row: 1;
            animation: var(--_circle-animation) var(--_circle-duration) infinite cubic-bezier(0.35, -0.96, 0.94, 2.34);
            width: calc(var(--_circle-size) + 10vw);
        }

        // Circle modifiers
        $circles-data: (
        ('01', 45s, 28rem),
        ('02', 20s, 34rem, reverse),
        ('03', 40s, 36rem, alternate-reverse),
        ('04', 15s, 45rem, alternate),
        ('05', 120s, 65rem, alternate-reverse)
        );

        @each $data in $circles-data {
            $num: list.nth($data, 1);
            $duration: list.nth($data, 2);
            $size: list.nth($data, 3);

            .circle--#{$num} {
                --_circle-duration: #{$duration};
                --_circle-size: #{$size};

                @if list.length($data) == 4 {
                    $direction: list.nth($data, 4);
                    animation-direction: $direction;
                }
            }
        }

    }

}
        


.hero--backdrop {
    height:100vh; width: 100vw;

    position: absolute;
    top: 0; left: 0;

    // inlined for performance:
    // background: var(--wolf, #045);
    // background-position: center center;
    // background-size: auto 120%;

    isolation: isolate;
    z-index: 100;

    .logo-fragment {
        width: 55vw;
        width: 85vw;

        // inlined for performance:
        // background: var(--logo_plainwhite);
        // background-position: right -10vh;
        // background-size: 165vh;

        mix-blend-mode:exclusion;
        
        background-repeat: no-repeat;
    }

}




.buttons {
    position: absolute;
    top: 70%;
    bottom: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    font-size: 1.4rem;

    @include mobile {
        flex-direction: column-reverse;
        gap: 2rem;
    }
}

</style>