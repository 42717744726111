<template>
  <div id="theme" :data-theme="theme">
  
      <TheSplashScreen id="splash" v-if="loadingStatus" :maintenance="maintenance" />
  
      <TheNavigation 
      :navType="navType"
      :theme-click="(value) => theme = value"
      :pages="pages"
      />
      <Loc
          :page-loc="locations[0]"
          :tab-loc="locations[1]"
          :item-loc="locations[2]"
          />
  
      <router-view
      v-slot="{Component, route}" 
      :id="$route.name" 
      class="page">
          <transition :name="route.meta.transition || 'default'">
              
              <component 
              :is="Component" 
              :key="$route.name"
              :pages="pages"
              :articles="articles"
              :set-locations="(value, at) => locations[at] = value"
              :locs="locations"
              :maintenance="maintenance"
              ></component>
  
          </transition>
      </router-view>
    
  </div>
  </template>
  
  <script>
  import TheNavigation from '@/components/TheNavigation.vue'
  import TheSplashScreen from '@/components/TheSplashScreen.vue'
  import Loc from '@/components/LocationTracker.vue'
  
  export default {
    components: {
      TheNavigation,
      TheSplashScreen,
      Loc
  },
    data() {
      return {
          loadingStatus: true,
          maintenance: true,
          activePage: 0,
          theme: 'default', 
          navType: 'fixed',
          locations: [0,0,0,0],
          openNav: false, 
          openSettings: false,
          
          pages: [
          {
              name: 'Home & News',
              slug:'home',
              pageTitle: 'Home, About Us & News',
              content: [
              {
                  
                  sections: [
                      {
                      name: 'info',
                      text: 'just some information in general',
                      hasTabs: true,
                      tabs: [
                              {title: 'News', header: 'Anidaza Cartel Gazette'},
                              {title: 'Credo', header: 'Chill Eve Experience, Laid back PvP & casual mining'},
                              {title: 'Join', header: 'Open the gates to Combat, Buybacks, Moons, and more...'},
                              {title: 'About', header: 'Piracy, moon mining, hotdrops & camping'}
                          ]
                      },
                      {
                      name: 'contact',
                      text: 'there will be contact',    
                      hasTabs: false
                      }
                  ],
              }, 
              {
                  sections: [
                      {
                      name: 'socials',
                      text: 'here will be links'
                      },
                      {
                      name: 'quickinfo',
                      text: 'very quick info'
                      }
                  ]
              }
              ]
          },
          {
              name: 'Tools & Services',
              slug: 'tools_overview',
              pageTitle: 'Eve Online Tools & Services',
              content: [
              {
                  sections: [
                      {
                          hasTabs: true,
                          tabs: [
                              {title: 'Overview', path: 'tools_overview', tabname:'tools_overview',   header: 'Eve Online Tools & Services'},
                              {title: 'Guides',   path: 'tools_guides',   tabname:'guides',           header: 'Space Guides and Tutorials to survive in New Eden'},
                              {title: 'Hauling',  path: 'tools_hauling',  tabname:'hauling',          header: 'Jump Freighter Service Fee Calculator'},
                              // {title: 'Moons',    path: 'tools_',   header: 'Lowsec Moon Survey Database'},
                              // {title: 'Planets',  path: 'tools_',   header: 'Planetary Industry Setup Helper'}
                          ],
                          name:'services',
                          text:'this page for tools n such'
                      }
                  ]
              }
              ]
          },
          {
              name: 'Disclaimer',
              slug: 'legal',
              pageTitle: 'Legal Disclaimers',
              content: [
              {
                  tabs: false,
                  sections: [
                      {
                      name: 'disclaimer',
                      text: 'some legal disclaimer stuff regarding the game and assets and whatnot',
                      additions: [
                          {
                          name: 'CCP',
                          text: 'All EVE related materials are property of CCP Games. EVE Online and the EVE logo are the registered trademarks of CCP hf. All rights are reserved worldwide. All other trademarks are the property of their respective owners. EVE Online, the EVE logo, EVE and all associated logos and designs are the intellectual property of CCP hf. All artwork, screenshots, characters, vehicles, storylines, world facts or other recognizable features of the intellectual property relating to these trademarks are likewise the intellectual property of CCP hf. CCP hf. has not granted permission to anidaza.space to use EVE Online and all associated logos and designs for promotional and information purposes on its website since they probably aren\'t aware of our existence and thus do not endorse, and are not in any way affiliated with, anidaza.space. CCP is in no way responsible for the content on or functioning of this website, nor can it be liable for any damage arising from the use of this website.'
                          },
                          {
                          name: 'Outgoing Links',
                          text: 'Despite best effort, we can not and will not take responsibility for \
                                  content on, or the availability of, other websites. Should you discover \
                                  a broken link or worse, we would appreciate it if you could let us know. \
                                  In any case, be mindful about clicking random internet links :)'
                          },
                          {
                          name: 'Privacy Policy',
                          text: 'Our website does not collect any personal information from its users. \
                                  We do not ask for or store any information that could be used to identify \
                                  you personally, such as your name, email address, or phone number. \
                                  We may collect non-personal information about your use of our website, such as \
                                  the pages you visit and the links you click on. This information is collected \
                                  for statistical purposes only and is not used to identify you personally. \
                                  If you have any questions about our privacy policy, please contact us.'
                          },
                          {
                              name: 'Local Storage Usage',
                              text: 'We use the local storage of your browser to save your preferred color \
                                      theme and nav display settings. This data is not shared with third-parties \
                                      and cannot be used to identify you. You can clear your local storage at any \
                                      time by using your browser\'s settings. Doing so will simply revert these \
                                      settings to default values and should not impair your usage of our website.'
                          }
                      ]
                      }
                  ]
              }
              ]
          }
          ],
          articles: [
          {
              parent: "template", featured: true,
              name: "Article Data Template",
              slug: "article_data_template",
              author: "unknown",
              timestamp: '794714400',
              content:
              [{   
                  blip: "This is the first paragraph, which should be shown on the homepage as a blip. It shall be followed\
                  up by a link to a seperate page, which will show the full text of the whole article and potentially details\
                  like author and similar articles. Ideally, this blip is interesting enough to persuade readers to click the\
                  'read more' button, while being short enough so as to leave room for other articles on display.",
                  text: [
                  "The second paragraph is only revealed on the actual article page. This goes for every further paragraph too."
                  ,
                  "All paragraphs are to be written in this form."              
                  ]
              }],
              tags: ["Template"],
              img: [
                  {
                      src: "https://wallpaperset.com/w/full/9/a/2/461950.jpg",
                      alt: "space bubble art"
                  }
              ]
              },
              {
              parent: "news",
              name: "The Anidaza Cartel Unveils Stunning Redesign of their Website, Empowering Capsuleers Across the Cluster",
              slug: "the_anidaza_cartel_unveils",
              author: "ChatGPT, annotated and edited by Ray Halcyon",
              timestamp: '1688133600',
              content:
              [{   
                  blip: "New Eden, June 30, YC125 — The Anidaza Cartel, one of the most prominent and influential \
                  organizations in New Eden, has officially launched their highly anticipated website redesign. \
                  With a sleek and intuitive interface, the revamped online platform promises to provide an \
                  enhanced user experience for capsuleers across the cluster.",
                  text: [
                  "The Anidaza Cartel, known for their formidable presence in trade, manufacturing, and finance, \
                  has always been at the forefront of innovation.¹ This latest venture into the digital domain \
                  showcases their commitment to staying ahead in the ever-evolving landscape of New Eden. The \
                  redesigned website, built upon state-of-the-art technology, aims to offer a comprehensive and \
                  seamless portal for both members and non-members alike."
                  ,
                  "Visitors to the new Anidaza Cartel website will immediately be captivated by its modern \
                  and stylish aesthetics. The sleek design, incorporating the organization's iconic logo and \
                  colors, presents a visual feast that resonates with the Cartel's brand identity. Furthermore, \
                  the user-friendly layout ensures that even the most novice users can effortlessly navigate the site."
                  ,
                  "A notable addition to the revamped website is the \"Knowledge Repository\"² a treasure trove \
                  of information meticulously curated by the Cartel's renowned experts. Pilots seeking insights \
                  into the intricacies of market dynamics, industrial strategies, and financial management will \
                  find a wealth of resources at their fingertips. The Anidaza Cartel's commitment to sharing \
                  knowledge sets them apart as a trailblazer in fostering growth and prosperity within the \
                  capsuleer community."
                  ,
                  "In line with their dedication to nurturing talent, the Cartel's website boasts a section \
                  dedicated to recruitment and career opportunities.³ Aspiring entrepreneurs and ambitious \
                  pilots can explore a range of positions available within the organization. From skilled \
                  traders and shrewd financiers to brilliant engineers and talented researchers, the Anidaza \
                  Cartel aims to attract the best and brightest minds across New Eden.⁴"
                  ,
                  "Furthermore, the redesigned website provides seamless integration with the Cartel's \
                  communication platforms⁵, allowing capsuleers to connect with fellow members in real-time. \
                  A sophisticated messaging system facilitates secure and efficient communication, enabling \
                  the Cartel's network to flourish and adapt to the rapidly changing galactic landscape."
                  ,
                  "\"The launch of our new website marks an important milestone for the Anidaza Cartel,\" \
                  said Ray Halcyon, the Cartel's founder and CEO. \"We envision a future where \
                  every capsuleer, regardless of their background or experience, can benefit from our knowledge \
                  and resources. Our redesigned website is a testament to our commitment to fostering \
                  collaboration, innovation, and growth within the New Eden community.\"⁶"
                  ,
                  "The Anidaza Cartel's website redesign is seen by many as a significant step forward, \
                  not only for the organization but for the entire capsuleer community. As the Cartel \
                  continues to expand its influence and reach, this digital transformation ensures that \
                  their valuable expertise and resources are accessible to a broader audience, empowering \
                  pilots from all walks of life to thrive in the cutthroat world of New Eden."
                  ,
                  "To experience the new Anidaza Cartel website and unlock a wealth of opportunities, visit \
                  their portal at www.anidaza.space and embark on a journey towards boundless prosperity."
                  ,
                  "<i>This news article has been sponsored by the Anidaza Cartel.</i>⁷"
                  ,
                  "______"
                  ,
                  "<b><u>Editor's Note</u>:</b> Originally I only asked ChatGPT to write an article so I could get \
                  some inspiration on what to say, but the style and near accuracy (ignoring a couple \
                  of yet-to-be-announced things) made me laugh, so I thought why not share it. It's \
                  hilarious to me how high ChatGPT thinks of us given that lowsec pirates are usually \
                  looked down upon by the rest of the playerbase, so I hope y'all can get a chuckle out \
                  of that too. Annotations below to correct a couple details by the way."
                  ,
                  "¹) I'm sure that's one way to put it.<br>\
                  ²) There is indeed a feature planned that meets this description. On the old website we \
                  called them \"Space Guides\" but \"Knowledge Repository\" definitely sounds slick.<br>\
                  ³) We do, indeed, have a recruitment section. As for positions, opportunities and such, \
                  you'll have to talk to us though :)<br>\
                  ⁴) Mostly looking for likeminded pirates who enjoy mining occasionally to be honest, but \
                  we have a vast network of connections that'll help you with any of your ventures.<br>\
                  ⁵) I'm starting to think the AI somehow read my notes.... integration for our discord \
                  server is indeed planned lol.<br>\
                  ⁶) I'd be hardpressed to formulate it any other way. Good bot.<br>\
                  ⁷) Absolutely amazing. Not sure how we sponsor an article generated for free, but this is 100% \
                  something you'd find in one of our public channels."
                  ,
                  "Hope everyone reading this has a great day, and as always, fly safe o7"
                  ]
              }],
              tags: ["Website", "update", "ChatGPT"],
              img: [
                  {
                      src: "bg/wolf.webp",
                      alt: "Assault FRigate Wolf Class Eve Online Anidaza Constellation in New Eden"
                  }
              ]
              },
              {
              parent: "test", featured: true,
              name: "1000 more words from our sponsor",
              slug: "test_1000_words",
              author: "Ray Halcyon",
              timestamp: '8800',
              content:
              [{   
                  blip: "This is the first paragraph, which should be shown on the homepage as a blip. It shall be followed\
                  up by a link to a seperate page, which will show the full text of the whole article and potentially details\
                  like author and similar articles. Ideally, this blip is interesting enough to persuade readers to click the\
                  'read more' button, while being short enough so as to leave room for other articles on display.",
                  text: [
                  "Doloribus quo laudantium quod dolor sint deleniti aliquam tempore optio expedita est. At necessitatibus quae ducimus cum suscipit, quisquam blanditiis, corrupti sint adipisci veniam magni, quidem atque totam. Illo, ipsum? \
                  Aut numquam eveniet, adipisci quo tempore molestiae animi veritatis libero eos nam dignissimos voluptatem magni corporis. Possimus fugit illo odio, mollitia dolor temporibus. Quod magnam molestiae velit molestias fugit quia."
                  ,
                  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe ipsa, sed rerum ratione tempore voluptatum at doloribus est accusamus dicta amet nulla voluptates quisquam accusantium incidunt quasi sequi ipsam eveniet. \
                  Mollitia atque, sapiente, delectus sunt laudantium at incidunt aliquam voluptatibus cum esse, recusandae amet praesentium. Corporis tenetur, fuga, quia repudiandae eveniet quis soluta similique hic dolorem illum qui asperiores. Explicabo."              
                  ]
              }],
              tags: ["1000 Words", "idk", "tag1"],
              img: [
                  {
                      src: "bg/panther.webp",
                      alt: "universe fantasy nebula space art planet colorful"
                  }
              ]
              },
              {
              parent: "test",
              name: "Test Some Words Article",
              slug: "test_some_words",
              author: "Author Name",
              timestamp: '987654321',
              content:
              [{   
                  blip: "This is the first paragraph, which should be shown on the homepage as a blip. It shall be followed\
                  up by a link to a seperate page, which will show the full text of the whole article and potentially details\
                  like author and similar articles. Ideally, this blip is interesting enough to persuade readers to click the\
                  'read more' button, while being short enough so as to leave room for other articles on display.",
                  text: [
                  "Doloribus quo laudantium quod dolor sint deleniti aliquam tempore optio expedita est. At necessitatibus quae ducimus cum suscipit, quisquam blanditiis, corrupti sint adipisci veniam magni, quidem atque totam. Illo, ipsum? \
                  Aut numquam eveniet, adipisci quo tempore molestiae animi veritatis libero eos nam dignissimos voluptatem magni corporis. Possimus fugit illo odio, mollitia dolor temporibus. Quod magnam molestiae velit molestias fugit quia."
                  ,
                  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe ipsa, sed rerum ratione tempore voluptatum at doloribus est accusamus dicta amet nulla voluptates quisquam accusantium incidunt quasi sequi ipsam eveniet. \
                  Mollitia atque, sapiente, delectus sunt laudantium at incidunt aliquam voluptatibus cum esse, recusandae amet praesentium. Corporis tenetur, fuga, quia repudiandae eveniet quis soluta similique hic dolorem illum qui asperiores. Explicabo."              
                  ]
              }],
              tags: ["tag1", "tag2"],
              img: [
                  {
                      src: "https://wallpaperset.com/w/full/9/a/2/461950.jpg",
                      alt: "space bubble art"
                  }
              ]
              },
              {
              parent: "news", featured: true,
              name: "Re-Launch of the Space Guides Knowledge Repository, Charting a Course for Aspiring Space Pirates",
              slug: "grand_reopening_space_library",
              author: "Ray Halcyon",
              timestamp: '987654321',
              content:
              [{   
                blip: "New Eden, July 12, YC125 — The notorious Anidaza Cartel, a formidable \
                alliance of space pirates operating in low security space, unveils their \
                revamped Space Guides, a knowledge repository aimed at welcoming both novice \
                and returning pilots. This comprehensive resource equips aspiring space pirates \
                with the tools and knowledge needed to thrive in the cutthroat world of New Eden.",
                text: [
                "Building upon our previous iteration, these Guides serve as a compass for those \
                seeking to embrace the lucrative yet treacherous lifestyle of piracy. A repository \
                of articles encompassing an ever growing collection of invaluable insights, \
                strategies, and practical tips to navigate the dangers and complexities of low \
                security space."
                ,
                "Our Space Guides are our way of empowering pilots to embrace the thrill and rewards \
                of space piracy. We offer a wealth of information covering a wide range of topics \
                vital to aspiring space pirates. From navigating New Eden as an Outlaw, to tactics \
                for evading law enforcement and maximizing plunder, these Guides lay the groundwork \
                for successful forays into the lawless realms of New Eden. In addition to tactical \
                know-how, Our Space Guides also delves into the nuances of industrial activities, \
                markets and trade, and covert operations, ensuring that budding pirates are \
                well-versed in the intricacies of the pirate lifestyle and well-funded to compete \
                with the big players within no time."
                ,
                "\"We believe that piracy, when done right, can be an art form,\" emphasized \
                Gadsen Heydt, the enigmatic mastermind behind this project. \"Our Space Guides \
                enable pilots to embrace their inner renegade, honing their skills and shaping \
                their destinies amidst the chaos of low security space.\" To access our knowledge \
                repository, simply head over to the tools section of our website or click here: \
                <a href=\"https://www.anidaza.space/guides\">Space Guides</a>"
                ,
                "<u>Disclaimer</u>: ChatGPT was used in the first draft of this article, however \
                the final version has been edited to a degree where I probably didn't have to use \
                it in the first place. The article image was created using <a href=\"https://hotpot.ai/art-generator\"\
                target=\"_blank\" alt=\"Hotpot AI Art Generator tool\">Hotpot AI</a> and only lightly \
                edited."
                ]
              }],
              tags: ["Don't Panic", "Website", "Guides"],
              img: [
                  {
                      src: "bg/library.webp",
                      alt: "AI generated space library"
                  }
              ]
              },
              {
              parent: "guides", category: "Navigation",
              name: "Using Local Intel to your Advantage",
              slug: "local_intel_advantage",
              author: "Gadsen Heydt",
              timestamp: '1675272600',
              content:
              [{   
                  blip: "The <strong>Local Chat</strong> window is your number one <strong>intel tool</strong> \
                  in Eve Online. No matter if you're half-afk ratting, mining, running missions or actively \
                  hunting targets, you want to have your local chat window open and visible on your screen at \
                  all times.",
                  text: [
                  "The Local Chat tells you <strong>who is in system with you</strong> and gives you a way of \
                  looking up <strong>potential threats</strong> with tools such as <a target=\"_blank\" href=\"\
                  https://zkillboard.com/\">zkillboard</a> for the odd neutral pilot you encounter. If there's \
                  many neutrals, you can copy and paste the member list of local chat into <a target=\"_blank\" \
                  href=\"https://localthreat.xyz/\">Localthreat</a> or <a target=\"_blank\" href=\"\
                  https://dscan.info/\">D-Scan Info</a> to get a quick picture of who's there."
                  ,
                  "To <strong>seperate local</strong> from the rest of your chat windows, simply drag and drop \
                  the chat name to a free spot on your screen. Alternatively, if your ingame settings are set \
                  up that way, hold shift while doing so."
                  ]
              }],
              tags: ["ELI5", "Intel", "Travel"],
              img: ['none']
              },
              {
              parent: "guides", category: "Navigation",
              name: "The MWD + Cloak Trick",
              slug: "mwd_cloak_trick",
              author: "Ray Halcyon",
              timestamp: '1673370180',
              content:
              [{   
                  blip: "The MWD + Cloak Trick is an essential tool when transporting loot, evading gankers, gatecamps or \
                  generally trying to get from point A to point B. <br>\
                  Before trying it in lowsec, you should practice it in highsec until your muscle memory is on point.",
                  text: [
                  "You need an <u>Improved Cloaking Device II</u> and a <u>correctly sized MWD</u> to make it work. \
                  Practice hitting your F1 and F2 keys with the right timing in highsec until you get it right \
                  consistently - it will save you many times."
                  ,
                  "How to do it? Easy:<br>\
                  When you jump into a system.... \
                  <ol>\
                  <li><strong>Align</strong> to destination</li>\
                  <li>immediately <strong>activate MWD and cloak</strong></li>\
                  <li><strong>wait</strong> until MWD cycle completes about 3/4th*</li>\
                  <li>deactivate Cloak, <strong>spam Warp</strong> to</li>\
                  </ol>"
                  ,
                  "*) The exact timing is determined by ship mass, type, speed and align time, it works for every \
                  hauler though. For a DST you'll want to let the cycle complete to around 95% before decloaking and warping."
                  ]
              }],
              tags: ["ELI5", "Travel"],
              img: ['none']
              },
              {
              parent: "guides", category: "Navigation",
              name: "Bottlenecks: Dangerous Locations you'll want to avoid",
              slug: "bottlenecks_dangerous_locations",
              author: "Gadsen Heydt",
              timestamp: '1674739200',
              content:
              [{   
                  blip: "In broad terms, bottlenecks in New Eden are locations with a lot of traffic, which \
                  naturally attract a lot of pilots seeking to cause mayhem. Gankers in highsec and pirats \
                  in lowsec tend to make systems their home that provide them with frequent PvP opportunities.",
                  text: [
                  "Even though perfecting the <strong>MWD + Cloak Trick</strong> will get you through most \
                  camps in high and low security space, sometimes it's better to avoid them entirely."
                  ,
                  "To find out whether a route is generally dangerous or not, always check how many kills \
                  happen per hour and per 24 hours. You can easily do this on <a target=\"_blank\" href=\"\
                  https://evemaps.dotlan.net/\">Dotlan</a> or <a target=\"_blank\" href=\"\
                  http://eve-gatecheck.space/eve/\">GatecampCheck</a>. If you're part of the Cartel, we have \
                  a channel tracking losses in Ahbazon. I invite you to unmute that channel for a couple days \
                  to get a feeling for how dangerous this system is."
                  ,
                  "Most bottlenecks are easily identifiable by looking at maps and figuring out common \
                  routes. Here's a short list of the most notorious systems you might want to have on your \
                  <strong>Avoid List</strong> You can right-click a system link ingame and select 'Avoid this System'"            
                  ],
                  table: [
                      ['System', 'Security Level', 'Notes'],
                      ['Ahbazon', 'Lowsec', 'Shortest route from Jita to Amarr, "the" deathtrap'],
                      ['Vecamia', 'Lowsec', 'Second shortest route from Jita to Amarr, very often camped'],
                      ['Rancer', 'Lowsec', 'Shortest route from Jita to Hek, often camped, usually with Smartbombs too'],
                      ['Uedama', 'Highsec', '0.5 system on the route from Jita to Amarr, a lot of gankers live here']
                  ]
              }],
              tags: ["Maps", "Travel", "Hauling"],
              img: ['none']
              },
              {
              parent: "guides", category: "Navigation",
              name: "Home Stations, deathcloning and Jumpclones",
              slug: "home_stations_deathcloning_jumpclones",
              author: "Ray Halcyon",
              timestamp: '1676471460',
              content:
              [{   
                  blip: "You can find and set your home station in your <strong>Character Sheet</strong>,\
                  which opens when you click your pilot's portrait in the upper left corner. Tab 'Character',\
                  subtab 'Home Station' will show your current home station, which is where you respawn after\
                  getting podded (pod killed) as well as the options to set your current location as home\
                  station when docked up and the option to self-destruct, which will move you to your home station.",
                  text: [
                  "This process is called <strong>Deathcloning</strong>, and can be done in any station.\
                  Double check you don't have implants plugged in, otherwise they'll get destructed alongside your clone."
                  ,
                  "If you have implants or want to be able to come back to this station, you should <strong>\
                  Jumpclone</strong> instead. Jumpclones require the <strong>Infomorph Psychology</strong>\
                  skill to be trained. Every time you jump to a jumpclone, your old clone with its implants\
                  will be installed at your old location, which costs a small sum of ISK."
                  ,
                  "To install jumpclones in a station, the station in question has to have a clone bay facility. \
                  In your Character Sheet, tab 'Character', subtab 'Jump Clones' you can see all your jumpclones, \
                  install new ones and destruct existing ones."
                  ,
                  "To install a clone in a <strong>Station without Clonebay</strong>, dock up and jump to an \
                  existing clone in a different station. This will automatically install your old clone in that station."
                  ,
                  "Jumping to a different clone within <strong>the same station</strong>, does not have a cool \
                  down timer. Jumping to a different station has a 24h cooldown timer, this can be reduced by \
                  training the <strong>Infomorph Synchronizing</strong> skill."     
                  ],
              }],
              tags: ["ELI5", "Maps", "Travel", "Clones"],
              img: ['none']
              },
              {
              parent: "guides", category: "Combat",
              name: "Security Status",
              slug: "security_status",
              author: "Gadsen Heydt",
              timestamp: '1673371860',
              content:
              [{   
                  blip: "When looking at a character ingame, you might have noticed different pilots having \
                  varying values of Security Status. The values ranges from +10 to -10 and can be influenced \
                  by various ingame acts.",
                  text: [
                  "In general, attacking other capsuleers lowers the sec. status while killing NPC pirates \
                  raises it. Pilots with a negative sec. status face penalties in high security areas of New \
                  Eden, becoming Outlaws at -5 at which point they are legal targets in all empire space and \
                  can be shot without repercussions from CONCORD."
                  ,
                  "If your Security Status drops <strong>below -5</strong> you become an <strong>Outlaw</strong> \
                  and Faction Police will pursue you in all high security systems. Make it to -10 to earn street \
                  cred among your fellow Pirates, but be aware that Stations in high security space will deny \
                  you docking access in a ship and you\'re now a legal target for other Pilots in highsec."
                  ,
                  "Negative security status will limit your gameplay a little before you become a real Outlaw: \
                  Faction Police NPCs will start attacking you after about 10 seconds if your status is below:"    
                  ],
                  table: [
                      ['', 'Your Sec. Status', 'System Security Status'],
                      ['', 'Status -2.0', '1.0 System'],
                      ['', 'Status -2.5', '0.9 System'],
                      ['', 'Status -3.0', '0.8 System'],
                      ['', 'Status -3.5', '0.7 System'],
                      ['', 'Status -4.0', '0.6 System'],
                      ['', 'Status -4.5', '0.5 System']
                  ]
              }],
              tags: ["Piracy", "Intel"],
              img: ['none']
              },
              {
              parent: "guides", category: "Market",
              name: "Tradehubs and Markets",
              slug: "tradehubs_markets",
              author: "Mirya Mayaki",
              timestamp: '1674850020',
              content:
              [{   
                  blip: "Tradehubs are stations where most trade occurs. They're usually better stocked \
                  than other systems and have more stable prices. The biggest tradehub in New Eden is \
                  <strong>Jita IV-4</strong> in The Forge region.",
                  text: [
                  "While Jita is the biggest, every region has a tradehub or even multiple hubs. These \
                  can change over time or develop due to pilots congregating in specific areas. Systems \
                  with a central location or good mission agents often times draw in traders and market \
                  PvPers looking for business."   
                  ],
                  table: [
                      ['Empire', 'Region', 'Tradehub', 'Full Station Name'],
                      ['Caldari', 'The Forge', 'Jita IV-4', 'Jita IV - Moon 4 - Caldari Navy Assembly Plant'],
                      ['Amarr', 'Domain', 'Amarr VIII', 'Amarr VIII (Oris) - Emperor Family Academy'],
                      ['Minmatar', 'Metropolis', 'Hek VIII-12', 'Hek VIII - Moon 12 - Boundless Creation Factory'],
                      ['Gallente', 'Sinq Laison', 'Dodixie IX-20', 'Dodixie IX - Moon 20 - Federation Navy Assembly Plant'],
                      ['Minmatar', 'Heimatar', 'Rens VI', 'Rens VI - Moon 8 - Brutor Tribe Treasury']
                  ]
              }],
              tags: ["ELI5", "Maps", "Markets"],
              img: ['none']
              },
              {
              parent: "guides", category: "Market",
              name: "Jita Value and Estimated Prices",
              slug: "the_gold_standard",
              author: "Mirya Mayaki",
              timestamp: '1674853500',
              content:
              [{   
                  blip: "When you open the ingame Market Window, you will notice that the market \
                  systems in New Eden are seperated by regions. Every region has minor tradehubs \
                  which evolve over time depending on activity, availability and effort put in by \
                  individual market seeders.",
                  text: [
                  "When looking up items, you can filter availability of market orders for the \
                  station you are docked in, the system you are currently in and the whole region. \
                  The top section will show sell orders you can buy from, the bottom section shows \
                  buy orders you can sell to."
                  ,
                  "Since ingame markets are split up by region, capsuleers tend to use the \
                  rates of the biggest tradehub, Jita IV-4, to appraise the value of their assets \
                  universally. This gold standard is referred to by <strong>Jita Buy Value</strong> \
                  and <strong>Jita Sell Value</strong> or JBV and JSV respectively. There are a number\
                  of web tools that can be used to appraise items without having to physically travel to Jita."
                  ,
                  "However the values items are traded for in the region you are currently in will \
                  reflect in the estimated value you can see on the bottom of your inventory. For \
                  this reason be aware that in most regions, the estimated value does not reflect \
                  the actual value of your items, hence the more common usage of Jita pirces."  
                  ]
              }],
              tags: ["ELI5", "ISK", "Assets", "Markets"],
              img: ['none']
              },
              {
              parent: "guides", category: "Industry",
              name: "Moon Ore Types",
              slug: "moon_ore_asteroids",
              author: "Jens Kreiliger",
              timestamp: '1673635020',
              content:
              [{   
                  blip: "There are 5 general types of Moon Asteroids, ranging from r4 to r64. While moons \
                  can be mined in any system with a security rating of 0.5 and below, High Security and \
                  Wormhole space only have r4, so called Ubiquitous ore.",
                  text: [
                  "The moons in low and null security space can spawn all types of ore, however there is a \
                  noticable distribution of regional moon asteroid types so that specific asteroids can only \
                  be found in their respective regions."
                  ,
                  "The minerals contained in Moon Asteroids are unique to Moons, making them an important \
                  part of production and industry in New Eden. In contrast to Belt Asteroids, Moon Asteroids \
                  can only be compressed inside or with the help of an Orca or Rorqual fitted with a Moon \
                  Ore Compression module."
                  ],
                  table: [
                      ['Type', 'Designation','Highsec', 'Lowsec', 'Nullsec', 'Wormholes'],
                      ['r4', 'Ubiquitous',      '✔',     '✔',     '✔',     '✔'],
                      ['r8', 'Common',          '',     '✔',     '✔',     ''],
                      ['r16', 'Uncommon',       '',     '✔',     '✔',     ''],
                      ['r32', 'Rare',           '',     '✔',     '✔',     ''],
                      ['r64', 'Exceptional',    '',     '✔',     '✔',     '']
                  ]
              }],
              tags: ["Moons", "Ore"],
              img: ['none']
              },
              {
              parent: "guides", category: "Industry",
              name: "Moon Minerals aka Moon Goo",
              slug: "moon_minerals_goo",
              author: "Jens Kreiliger",
              timestamp: '1673635020',
              content:
              [{   
                  blip: "Moon Ore contains valuable Minerals, colloquially referred to as \"moon goo\", \
                  which can be reacted using <strong>Reaction Formulas</strong> and service modules \
                  called <strong>Composite Reactors</strong> installed in Athanors in Lowsec.",
                  text: [
                  "Ubiquitous Moon Ore additionally contains 400 Mexallon and 2000 to 8000 Pyerite."
                  ],
                  table: [
                      ['', 'r64 Minerals', 'r32 Minerals','r16 Minerals', 'r8 Minerals', 'r4 Minerals'],
                      ['r64 Moon Ore', 'x22',      '',     'x10',     'x20',     'x20'],
                      ['Loparite', 'Promethium', '-', 'Platinum', 'Scandium', 'Hydrocarbons'],
                      ['Monazite', 'Neodymium', '-', 'Chromium', 'Tungsten', 'Ev.Deposits'],
                      ['Xenotime', 'Dysprosium', '-', 'Vanadium', 'Cobalt', 'Atm.Gases'],
                      ['Ytterbite', 'Thulium', '-', 'Cadmium', 'Titanium', 'Silicates'],
                      ['r32 Moon Ore', '', 'x50', '', 'x10', 'x15'],
                      ['Carnotite', '-', 'Technetium', '-', 'Cobalt', 'Atm.Gases'],
                      ['Cinnabar', '-', 'Mercury', '-', 'Tungsten', 'Ev.Deposits'],
                      ['Pollucite', '-', 'Caesium', '-', 'Scandium', 'Hydrocarbons'],
                      ['Zircon', '-', 'Hafnium', '-', 'Titanium', 'Silicates'],
                      ['r16 Moon Ore', '', '', 'x40', '', 'x10'],
                      ['Chromite', '-', '-', 'Chromium', '-', 'Hydrocarbons'],
                      ['Otavite', '-', '-', 'Cadmium', '-', 'Atm.Gases'],
                      ['Sperrylite', '-', '-', 'Platinum', '-', 'Ev.Deposits'],
                      ['Vanadinite', '-', '-', 'Vanadium', '-', 'Silicates'],
                      ['r8 Moon Ore', '', '', '', 'x40', ''],
                      ['Cobaltite', '-', '-', '-', 'Cobalt', '-'],
                      ['Euxenite', '-', '-', '-', 'Scandium', '-'],
                      ['Scheelite', '-', '-', '-', 'Tungsten', '-'],
                      ['Titanite', '-', '-', '-', 'Titanium', '-'],
                      ['r4 Moon Ore', '', '', '', '', 'x65'],
                      ['Bitumens', '-', '-', '-', '-', 'Hydrocarbons'],
                      ['Coesite', '-', '-', '-', '-', 'Silicates'],
                      ['Sylvite', '-', '-', '-', '-', 'Ev.Deposits'],
                      ['Zeolites', '-', '-', '-', '-', 'Atm.Gases']
                  ]
              }],
              tags: ["Moons", "Ore"],
              img: ['none']
              },
              {
              parent: "guides", category: "Navigation",
              name: "Daytripping in Wormhole Space",
              slug: "daytripping_in_wormhole",
              author: "Ray Halcyon",
              timestamp: '1676485020',
              content:
              [{   
                  blip: "While living in wormholes is very complicated, making a trip \
                  to wormhole space, also known as Anoikis or J-Space, can be comparably \
                  easy, fun and lucrative.",
                  text: [
                  "The main difference between known space and j-space is the lack of a local \
                  member list, leaving you in the dark about other pilots in the same system - \
                  until someone speaks in local, which adds them to the list temporarily."
                  ,
                  "For a successful daytrip into wormhole space, you will need the following:\
                  <ul>\
                    <li>Scanning Probes and Probelauncher to find sites, entries and exits</li>\
                    <li>Cloak, preferably Covert Ops Cloak to warp cloaked</li>\
                    <li>the Ability to constantly watch D-Scan</li>\
                    <li>a ship capable of survivng the sites you want to warp to</li>\
                    <li>the Know-How to make and use bookmarks</li>\
                    <li>enough Paranoia not to get caught by fellow capsuleers</li>\
                  </ul>"
                  ,
                  "There are various tools that make navigation easier, like Pathfinder \
                  and Tripwire, but your biggest help will be to remember to bookmark \
                  your wormhole entries and exits."
                  ,
                  "If you're part of the Cartel, you can find our Pathfinder linked on \
                  our discord server, as well as a break down on how and what to bookmark."
                  ,
                  "<u>A word of caution:</u> Warping blindly into a site you don't know \
                  about yet is never a good idea, but in Anoikis it will likely cost \
                  you your ship. Use tools like Rykki's Guide or similar to find out \
                  which sites you can safely warp to."
                  ],
              }],
              tags: ["Anoikis", "ELI5"],
              img: ['none']
              },
              {
              parent: "guides_tmp", category: "Navigation",
              name: "next",
              slug: "next",
              author: "Ray",
              timestamp: '1673635020',
              content:
              [{   
                  blip: "blip",
                  text: [
                  "p"
                  ],
              }],
              tags: ["tag"],
              img: ['none']
              },
          ]
      };
    },
    mounted() {
        setTimeout(() => {
            this.loadingStatus = false;
        }, 800);
    }
  }
</script>


<!-- <style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style> -->

<style>
  @import './assets/drip.css';

.default-enter-active,
.default-leave-active {
  opacity: 1;
  transition: opacity 500ms, transform 450ms;
}
.default-enter-from {
  opacity: .7;
  transform: translateY(-100%);
}
.default-leave-to {
  opacity: .7;
  transform: translateY(100%);
}



.fade-enter-active,
.fade-leave-active {
  transition: color 450ms;
}
.fade-enter-from,
.fade-leave-to {
    color: var(--bg_main, #191919);
}


</style>
