<template>
    <div>
        Instance-{{ pageLoc+1 || 0 }}{{ tabLoc+1 || 0 }}{{ itemLoc+1 || 0 }}-{{ Date.now() }}{{ getNetworkStatus() }}
    </div>
</template>

<script>
export default {
    props: {
        pageLoc: Number, 
        tabLoc: Number, 
        itemLoc: Number
    },
    data() {
        return {
            parse: {
                default: 'DFLT',
                polarized: 'PLRZ',
                glass: 'GL4S',
                fixed: 'F1XD',
                hidden: 'H1D3'
            }
    }
    },
    methods: {
        getNetworkStatus() {
            let online = navigator.onLine
            let theme = localStorage.getItem('theme')
            let navType = localStorage.getItem('nav')

            let themeSetting = ''
            let navSetting = ''

            if(theme) themeSetting = '-' + this.parse[theme]
            if(navType) navSetting = '-' + this.parse[navType]

            let status = themeSetting + navSetting + (online ? '-CONNECTED' : '-CONNECTION_ERROR')
            return status
        }
    }
}
</script>

<style scoped>
div {
    position:absolute;
    bottom:0; right:0;
    font-family:monospace;
    color:var(--inter_base);
    padding:.2rem;
    writing-mode:vertical-rl;
    transform:rotate(180deg);
    user-select:none;
}
</style>